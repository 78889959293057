import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c014f8f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "resultvoteField" }
const _hoisted_2 = {
  class: "resultvoteInfoWrapper shadow text-left",
  style: {"text-align":"left"}
}
const _hoisted_3 = { style: {"text-align":"center","margin-top":"-20px","margin-bottom":"40px"} }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { id: "foobr" }
const _hoisted_6 = {
  style: {"list-style-type":"none"},
  class: "nice-list"
}
const _hoisted_7 = { style: {"padding-bottom":"20px"} }
const _hoisted_8 = { class: "answerChoose row" }
const _hoisted_9 = { style: {"width":"80%","display":"inline-block"} }
const _hoisted_10 = { style: {"margin":"0","word-wrap":"break-word"} }
const _hoisted_11 = {
  key: 0,
  style: {"font-weight":"normal","font-size":"0.5em"}
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { style: {"width":"20%","display":"inline-block","text-align":"right"} }
const _hoisted_14 = ["disabled", "name", "value", "id"]
const _hoisted_15 = ["for"]
const _hoisted_16 = { style: {"text-align":"center"} }

export function render(_ctx: any,_cache: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", { innerHTML: _ctx.currentQuestion }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("form", _hoisted_5, [
        _createElementVNode("ul", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Answers, (value, key, index) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h3", _hoisted_10, [
                    _createTextVNode(_toDisplayString(key) + " ", 1),
                    (_ctx.gameMode=='Master')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, "( " + _toDisplayString(value[1].join(', ')) + " )", 1))
                      : _createCommentVNode("", true),
                    (_ctx.gameMode!='Master')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "(" + _toDisplayString(value[1].length) + "x)", 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    disabled: _ctx.gameMode!='Master',
                    name: 'answer-'+index,
                    value: value[0].join(', '),
                    id: 'answer-'+index
                  }, null, 8, _hoisted_14),
                  _createElementVNode("label", {
                    for: 'answer-'+index
                  }, null, 8, _hoisted_15)
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        (_ctx.gameMode=='Master')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addPoints && _ctx.addPoints(...args))),
              class: "btn blue-btn",
              style: {"margin-top":"30px"}
            }, " Punkte vergeben und Runde beenden "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}