import {SocketApi} from "@/data/socket/Socket";
import store from "@/store/index";
export default {
  namespaced:true,
  state: {
    playerList: [],
    currentUser:{},
  },

  getters: {
    getPlayers(state) {

      return state.playerList;
    },
    getAnswers(state) {
      let answers = [];
      let answerObject = {};


      for ( let key  in state.playerList) {
        let playersAnswer = state.playerList[key].answer.trim();
        if(playersAnswer==""){
          continue;
        }
        if (!answerObject[playersAnswer] ){
          answerObject[playersAnswer] = [];
          answerObject[playersAnswer].push([]);
          answerObject[playersAnswer].push([]);
        }
        answerObject[playersAnswer][0].push(state.playerList[key].socketId);
        answerObject[playersAnswer][1].push(state.playerList[key].username);
      }

      return answerObject;
    },
    getPlayersNr(state,nr) {
      return state.playerList[nr];
    },
    getPlayersCount(state) {
      let theCount = 0;
      for ( let key  in state.playerList) {
        theCount+=1;
      }
      return theCount;
    },
    getCurrentUser(state) {
      return state.currentUser;
    },
    getHighScore(state){
      let highScore: number = 0;
      for ( let key  in state.playerList) {
        if(highScore<state.playerList[key].points){
          highScore = state.playerList[key].points;
        }
      }
      return highScore;
    }
  },
  mutations: {
    addPlayerMut(state, Player) {
      state.playerList.push(Player);
    },
    updatePlayerlist(state, Playerlist:object) {
      state.playerList=Playerlist;

      let ownSocket=SocketApi.getInstance().getSocketId();

      for (let [key, value] of Object.entries(Playerlist)) {

        if(value.socketId==ownSocket){
          state.currentUser = value;
          if(value.master===true){
            store.dispatch("gameEnv/updateGameMode", 'Master');
          }
        };
      }
    },
  },


  actions: {
    addPlayer(context, Player) {
      context.commit('addPlayerMut', Player);
    },
    updatePlayerlist(context, Playerlist) {
      context.commit('updatePlayerlist', Playerlist);
    },
  },
};
