
import { Options, Vue, setup } from 'vue-class-component';

@Options({
  props: {
    username: String,
    color: String,
    icon: String,
    index: Number,
    connected: Boolean,
    points: Number,
    master: Boolean,
    foo:Boolean,
    answer: String,
  },
})

export default class User extends Vue {

  private propData:any = null;
  created() {
    let p:any = new Proxy(this.$props, {
      get: function(target, name) {
        return target[name];
      }
    });
    this.propData = p;
  }

  get pointHeight(){
    let height = 100;
    let max = this.$store.getters["playerList/getHighScore"];
    height = (this.propData.points /max )*100;
    return height+'%';
  }

  get amIactive(){
    if(this.propData.points){
      return false;
    }
    let currentUserIndex =  this.$store.getters["gameEnv/getCurrentuser"];
    return  this.propData.index==currentUserIndex;
  }


  get timerRunning(){
    if(this.$store.getters["gameEnv/getRound"] == 0) return false;
    if(this.$store.getters["gameEnv/getTimeString"]=="00:00"){
      return false;
    };

    return true;
  }

  get ColorAndIcon(){
    let colorsAndIcons =  this.$store.getters["gameEnv/getColorsAndIcons"];
    let colors = colorsAndIcons[0];
    let icons = colorsAndIcons[1];
    let nr = this.propData.index;
    let nrcol = nr % (colors.length);
    let nricon =  nr % (icons.length);

    return [colors[nrcol],icons[nricon]];
  }
}
