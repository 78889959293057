
import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: "alert",
  props: {
    message: null,
  },
  data() {
    return {
      showMe: false,
      timer: null
    }
  },
  watch: {
    message(to, from) {
      clearTimeout(this.$data.timer);

      if (to) {
        this.$data.showMe = true;
        this.$data.timer = setTimeout(() => {
          this.$data.showMe = false;
        }, 3000);
      } else {
        this.$data.showMe = false;
      }
    }
  },
  computed: {
    show() {
      return this.$data.showMe;
    }
  }
});
