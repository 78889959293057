import store from "@/store/index";
import {SocketApi} from "@/data/socket/Socket";

const MODE_MASTER = "Master",
  MODE_PLAYER = "Player",
  GAMEMODE_START = "start",
  GAMEMODE_PLAYING = "playing",
  GAMEMODE_RESULTS = "results",
  GAMEMODE_ANSWERS = "answers"

export default {
  namespaced: true,

  errors: [],

  state: {
    GameStore: {
      "id": null,
      "rounds": null,
      "difficulty": null,
      "timerLength": null,
      "sound": null,
      "status": null,
      "currentRound": null,
      "currentView": GAMEMODE_START,
      "currentUserIndex": null,
      "gameMode": MODE_PLAYER,
      "cards": null,
      "timeOfLastAction": 0,
      'currentCardIndex': 0,
      'wiggleItem': "",
      'resultList': ""
    },


    timer: null,
    currentTime: {
      min: 0,
      sec: 0,
      max: {
        min: 0,
        sec: 0,
      },
      currentString: '00:00'
    },

    GameColors: ['green', 'blue', 'red', 'orange'],
    visibleAnswer: true,
    GameIcons: [
      'fal fa-alicorn',
      'fal fa-badger-honey',
      'fal fa-bat',
      'fal fa-cat-space',
      'fal fa-cow',
      'fal fa-deer',
      'fal fa-dog',
      'fal fa-elephant',
      'fal fa-hippo',
      'fal fa-monkey',
      'fal fa-narwhal',
      'fal fa-otter',
      'fal fa-paw',
      'fal fa-pig',
      'fal fa-rabbit',
      'fal fa-ram',
      'fal fa-sheep',
      'fal fa-squirrel',
      'fal fa-cat',
      'fal fa-crow',
      'fal fa-dove',
      'fal fa-dragon',
      'fal fa-duck',
      'fal fa-feather',
      'fal fa-fish',
      'fal fa-frog',
      'fal fa-horse',
      'fal fa-kiwi-bird',
      'fal fa-pegasus',
      'fal fa-snake',
      'fal fa-spider',
      'fal fa-turtle',
      'fal fa-unicorn',
      'fal fa-whale',
      'fal fa-rabbit-fast',
      'fal fa-deer-rudolph',
      'fal fa-mandolin',
      'fal fa-music',
      'fal fa-list-music',
      'fal fa-piano',
      'fal fa-saxophone',
      'fal fa-trumpet',
      'fal fa-ufo'
    ]
  },
  getters: {
    isInRoom(state) {
      return state.GameStore.id != null;
    },
    getGameMode(state) {
      return state.GameStore.gameMode;
    },
    getRoomName(state) {
      return state.GameStore.id;
    },
    getRound(state) {
      return state.GameStore.currentRound;
    },
    getRounds(state) {
      return state.GameStore.rounds;
    },
    getCurrentView(state) {
      return state.GameStore.currentView;
    },
    getGame(state) {
      return state.GameStore;
    },

    getCurrentuser(state) {
      return state.GameStore.currentUserIndex;
    },

    getCurrentWiggleItem(state) {
      return state.GameStore.wiggleItem;
    },
    getStatus(state){
      return state.GameStore.status;
    },

    getColorsAndIcons(state) {
      return [state.GameColors, state.GameIcons];
    },
    isTimeRunning(state) {
      return state.timer !== null;
    },
    getCurrentTime(state) {
      return state.currentTime;
    },
    getTimeString(state) {
      return state.currentTime.currentString;
    },
    isMaster(state) {
      return state.GameStore.gameMode == MODE_MASTER;
    },
    getResultList(state){
      return state.GameStore.resultList;
    },
    getAnswerVisible(state){
      return state.visibleAnswer;
    }
  },
  mutations: {
    clear(state) {
      state.GameStore.id = null
      state.GameStore.rounds = null;
      state.GameStore.timerLength = null;
      state.GameStore.sound = null;
      state.GameStore.status = GAMEMODE_START;
      state.GameStore.currentView = GAMEMODE_START;
      state.GameStore.difficulty = null;
      state.GameStore.currentRound = null;
      state.GameStore.currentUserIndex = null;
      state.GameStore.gameMode = MODE_PLAYER;
      state.GameStore.cards = null;
      state.GameStore.currentCardIndex = 0;
      store.dispatch("gameEnv/showAnswer");
      state.GameStore.resultList = null;
      clearInterval(state.timer);
      state.timer = null;
      state.currentTime = {
        min: 0,
        sec: 0,
        max: {
          min: 0,
          sec: 0,
        },
        currentString: '00:00',
      }
    },
    addGameMut(state, Game) {
      state.GameStore.push(Game);
    },

    setWiggleItem(state,wiggleItem){
      state.GameStore.wiggleItem = wiggleItem;
    },

    updateGameData(state, GameStore) {
      state.GameStore.id = GameStore.id;
      state.GameStore.rounds = GameStore.rounds;
      state.GameStore.timerLength = GameStore.timerLength;
      state.GameStore.sound = GameStore.sound;
      state.GameStore.status = GameStore.status;
      state.GameStore.difficulty = GameStore.difficulty;
      state.GameStore.currentRound = GameStore.currentRound;
      state.GameStore.currentUserIndex = GameStore.currentUserIndex;
      state.GameStore.cards = GameStore.cards;
      state.GameStore.currentCardIndex = GameStore.currentCardIndex;
      state.GameStore.resultList =   GameStore.resultList;

      if (GameStore.currentRound === 0) {
        state.GameStore.currentView = GAMEMODE_START;
      }

      if (GameStore.currentRound <= GameStore.rounds) {
        state.GameStore.currentView = GAMEMODE_PLAYING;
      }

      if (GameStore.currentRound > GameStore.rounds) {
        state.GameStore.currentView = GAMEMODE_RESULTS;
      }
      store.dispatch("cardstore/createCurrentCardStack", GameStore.cards);
      store.dispatch("cardstore/activateCardsForRound", GameStore.currentCardIndex);
      store.dispatch("playerList/updatePlayerlist", GameStore.users);
    },
    resetGame(state) {
      state.GameStore.status = GAMEMODE_START;
      state.GameStore.currentRound = 0;
      state.GameStore.currentView = GAMEMODE_PLAYING;
      state.GameStore.wiggleItem = "";
    },
    updateGameMode(state, gameMode) {
      state.GameStore.gameMode = gameMode;
    },


    showAnswer(state) {
      state.visibleAnswer = true;
    },
    hideAnswer(state) {
      state.visibleAnswer = false;
    },

    stopTimer(state){
      store.commit("gameEnv/timerTrigger");
      state.currentTime.max.min = 0;
      state.currentTime.max.sec = 0;
      state.currentTime.min = state.currentTime.max.min;
      state.currentTime.sec = state.currentTime.max.sec;
      state.currentTime.currentString = '00:00';
      if (state.GameStore.gameMode == MODE_MASTER) {
        SocketApi.getInstance().send("job", {
          op: 'timer',
          timer: state.currentTime,
        });
      }
    },

    startTimer(state) {
      let _time = state.GameStore.timerLength.split(":");
      state.currentTime.max.min = parseInt(_time[0]);
      state.currentTime.max.sec = parseInt(_time[1]);
      state.currentTime.min = state.currentTime.max.min;
      state.currentTime.sec = state.currentTime.max.sec;
      state.currentTime.currentString = '00:00';

      clearInterval(state.timer);
      state.timer = setInterval(function () {
        store.commit("gameEnv/timerTrigger");
      }, 1000);
    },
    timerTrigger(state) {
      if (state.currentTime.sec == 0) {
        if (state.currentTime.min > 0) {
          state.currentTime.min--;
          state.currentTime.sec = 60;
        } else {
          clearInterval(state.timer);
          state.timer = null;
          return;
        }
      }
      state.currentTime.sec--;
      state.currentTime.currentString = ((state.currentTime.min < 10) ? "0" : "") + state.currentTime.min + ":" + ((state.currentTime.sec < 10) ? "0" : "") + state.currentTime.sec;
      if (state.GameStore.gameMode == MODE_MASTER) {
        SocketApi.getInstance().send("job", {
          op: 'timer',
          timer: state.currentTime,
        });
      }
    },
    updateTimer(state, timer) {
      state.currentTime = timer;
    }
  },
  actions: {
    clear({commit}: any) {
      commit('clear');
    },
    resetGame({commit}: any) {
      commit('resetGame');
    },
    setWiggleItem(context,item){
      context.commit('setWiggleItem',item);
    },
    addGame(context, Game) {
      context.commit('addGameMut', Game);
    },
    addPoints({commit, state}: any, data: { points: number, socketIds: string }) {
      SocketApi.getInstance().send("add_points", {
        points: data.points,
        room: state.GameStore.id,
        socketIds: data.socketIds
      });
    },
    addAnswer({commit, state}: any, data: { answer: string, socketId: string }) {
      SocketApi.getInstance().send("add_answer", {
        answer: data.answer,
        room: state.GameStore.id,
        socketId: data.socketId
      });
    },
    updateRoom({commit, state}: any, roomDetails) {
      if (state.GameStore.currentRound < roomDetails.currentRound) {
        commit('updateGameData', roomDetails);
       // commit('startTimer');
      } else {
        commit('updateGameData', roomDetails);
      }
    },
    updateGameMode(context, gameMode) {
      context.commit('updateGameMode', gameMode);
    },
    nextPlayer({state}: any) {
      console.log("store: nextPlayer");
      SocketApi.getInstance().send("next_player", state.GameStore.id);
    },
    actionMade({state}: any) {
      state.GameStore.timeOfLastAction = Date.now();
    },
    nextRound({state}: any) {
      console.log("store: nextRound");
      //TODO Check if last Round before send
      SocketApi.getInstance().send("next_round", state.GameStore.id);
    },
    nextCard({state}: any) {
      console.log("store: nextCard");
      SocketApi.getInstance().send("next_card", state.GameStore.id);
    },
    updateTimer({commit, state}, timer: any) {
      if (state.GameStore.gameMode !== MODE_MASTER) {
        commit("updateTimer", timer);
      }
    },
    startTimer({commit, state}){
      commit('startTimer');
    },
    stopTimer({commit, state}){
      commit('stopTimer');
    },
    showAnswer({commit,state}){
      commit('showAnswer');
    },
    hideAnswer({commit,state}){
      commit('hideAnswer');
    },
  },
};
