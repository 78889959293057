
import {Vue} from 'vue-class-component';
import {SocketApi} from "@/data/socket/Socket";

export default class Resultview extends Vue {
  foobar = "";

  get Results() {
    let ResultList = this.$store.getters["gameEnv/getResultList"];
    return ResultList;
  }

  get currentUser(){
    return this.$store.getters["playerList/getCurrentUser"];

  }

  get gameMode() {
    return this.$store.getters["gameEnv/getGameMode"];
  }
  get players(){
    return this.$store.getters['playerList/getPlayers'];
  }


}
