import store from "@/store/index";

export default {
  namespaced: true,
  state: {
    currentCardStack: [],
    currentCard: {},
    cardStack: [
      {
        msg: 'Wo geht man hin, wenn man Arbeit sucht?',
        difficulty: '1',
        title: 'Agentur für Arbeit',
        category: 'Arbeit',
        points: 1,
        explanation: 'Die Agentur für Arbeit hilft dir bei der Suche nach einer passenden Arbeitsstelle. Voraussetzung ist deine Arbeitserlaubnis. Oft zahlt die Agentur für Arbeit auch Fortbildungen oder Sprachkurse. Außerdem unterstützt dich die Agentur für Arbeit mit dem Arbeitslosengeld I. Dafür musst du in den letzten 2 Jahren mindestens 12 Monate versicherungspflichtig gearbeitet haben.',
        image: 'Agentur_fuer_arbeit.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was schließt man schriftlich ab, wenn man eine Arbeit gefunden hat?',
        difficulty: '1',
        title: 'Arbeitsvertrag',
        category: 'Arbeit',
        points: 1,
        explanation: 'Wenn du eine Arbeit gefunden hast, schließt du mit dem Arbeitgeber einen schriftlichen Arbeitsvertrag ab. Mit deiner Unterschrift versprichst du, dich an die Regeln zu halten. Der Arbeitgeber verspricht mit seiner Unterschrift, dich zu bezahlen.',
        image: 'Arbeitsvertrag.svg',
        active: false,
        played: false
      },
      {
        msg: 'Welche Abrechnung bekommt man monatlich vom Arbeitgeber?',
        difficulty: '1',
        title: 'Lohnabrechnung',
        category: 'Arbeit',
        points: 1,
        explanation: 'Du bekommst für jeden Monat eine Lohnabrechnung über die Anzahl deiner Arbeitsstunden und den Bruttolohn.\nAbgezogen werden davon die Lohnsteuer, die Kirchensteuer, der Solidaritätszuschlag sowie die Rentenversicherung, Arbeitslosenversicherung, Krankenversicherung und Pflegeversicherung.\nÜbrig bleibt der Nettolohn. Der wird auf dein Konto überwiesen \nÜberprüfe die Lohnabrechnung!',
        image: 'Lohnabrechnung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was schreibt man, wenn man ein Arbeitsverhältnis beenden möchte?',
        difficulty: '1',
        title: 'Kündigung',
        category: 'Arbeit',
        points: 1,
        explanation: 'Wenn du bei deinem Arbeitgeber aufhören möchtest, musst du schriftlich kündigen.\nDie Zeit zwischen der Kündigung und dem Vertragsende nennt man Kündigungsfrist.\nDie Kündigungsfrist steht im Arbeitsvertrag. Auch wenn der Arbeitgeber dir kündigt, muss er die Kündigungsfrist einhalten, damit du Zeit hast, dir eine neue Arbeit zu suchen. ',
        image: 'Kuendigung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man den Lohn, der im Arbeitsvertrag steht?',
        difficulty: '1',
        title: 'Bruttolohn',
        category: 'Arbeit',
        points: 1,
        explanation: 'Der Bruttolohn ist das Gehalt, das du mit deinem Arbeitgeber vereinbarthast. Davon werden noch Steuern, der Solidaritätszuschlag und Beiträge für die Sozialversicherungen abgezogen:\n▪ gesetzliche Krankenversicherung\n▪ Arbeitslosenversicherung \n▪ Pflegeversicherung \n▪ Rentenversicherung \n\nder Anteil deines Gehalts, der übrig bleibt, heißt Nettolohn. Der wird auf dein Konto überwiesen.',
        image: 'Bruttolohn.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wo kann man Geld beantragen, wenn man nicht genug zum Leben hat?',
        difficulty: '1',
        title: 'Jobcenter',
        category: 'Arbeit',
        points: 1,
        explanation: 'Wenn du keine Arbeit hast oder dein Arbeitslohn nicht zum Leben reicht, kannst du beim Jobcenter Arbeitslosengeld II (umgangssprachlich: Hartz IV) beantragen.\nDas Jobcenter sorgt dafür, dass dein Lebensunterhalt gesichert ist.',
        image: 'Jobcenter.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man die Zeit, in der man nicht arbeiten geht und zum Beispiel eine Reise mit der Familie macht?',
        difficulty: '1',
        title: 'Urlaub',
        category: 'Arbeit',
        points: 1,
        explanation: 'Jeder, der arbeitet, hat auch Recht auf bezahlten Urlaub. Das sind freie Tage, für die du Geld bekommst.\nWie viele Tage Urlaub du im Jahr hast, steht im Arbeitsvertrag. Wenn du Urlaub machen möchtest, sprichst du vorher mit deinem Chef.',
        image: 'Urlaub.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man die Zeit, in der man arbeitet?',
        difficulty: '1',
        title: 'Arbeitszeit',
        category: 'Arbeit',
        points: 1,
        explanation: 'Die Arbeitszeit steht in deinem Arbeitsvertrag. Das sind die Stunden,die du in einer Woche arbeitest. In manchen Arbeitsverträgen steht, in welchem Zeitraum du anwesend sein musst. In Deutschland gibt es Regeln, die dich als Arbeitnehmer schützen: \n▪ Du darfst nicht länger als 10 Stunden an einem Tag arbeiten.\n▪ Wenn du länger als 6 Stunden arbeitest, musst du eine Pause machen.',
        image: 'Arbeitszeit.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die kleine Chipkarte, die man in ein Handy steckt, um zu telefonieren?',
        difficulty: '1',
        title: 'SIM-Karte',
        category: 'Handy',
        points: 1,
        explanation: 'Die SIM-Karte ist eine kleine Chipkarte, die du in dein Handy steckst, damit du telefonieren und im Internet surfen kannst. Die SIM-Karte bekommst du von deinem Mobilfunkanbieter. Auf der SIM-Karte sind deine persönlichen Daten zur Identifikation im Mobilfunknetz gespeichert.\nDie SIM-Karte sicherst du mit einer geheimen Nummer – der PIN.',
        image: 'SIM-Karte.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die Karte für das Handy, bei der man im Voraus die Telefon- und Internetkosten bezahlt?',
        difficulty: '1',
        title: 'Prepaid-Karte',
        category: 'Handy',
        points: 1,
        explanation: 'Prepaid ist ein englisches Wort und bedeutet „im Voraus bezahlt“.\nFür das Handy kannst du eine Prepaid-Karte kaufen. Für diese Karte bezahlst du Geld und kannst dann dein Handy so lange benutzen, bis das „Guthaben“ – das Geld, das du bezahlt hast – verbraucht ist.\nBei einem Handyvertrag hingegen zahlst du die Kosten für die Internet- und Telefonnutzung jeden Monat im Nachhinein.',
        image: 'Prepaid-Karte.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man die Vereinbarung, die man bei einem Kauf abschließt?',
        difficulty: '1',
        title: 'Kaufvertrag',
        category: 'Handy',
        points: 1,
        explanation: 'Bei jedem Kauf schließen der Käufer und der Verkäufer einen Kaufvertrag.\nDer Kaufvertrag regelt, welche Ware zu welchem Preis gekauft oder verkauft wird. Außerdem enthält der Kaufvertrag Informationen zu Liefer- und Zahlungsbedingungen oder zur Garantie.\nEinen Kaufvertrag kannst du schriftlich oder mündlich abschließen. Auch wenn du ein gebrauchtes Handy von einem Bekannten kaufst, schließt du einen Kaufvertrag ab.',
        image: 'Kaufvertrag.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man die Zeit, für die ein Vertrag abgeschlossen wurde?',
        difficulty: '1',
        title: 'Vertragslaufzeit',
        category: 'Handy',
        points: 1,
        explanation: 'Der Zeitraum zwischen dem Beginn und dem Ende eines Vertrages nennt man Vertragslaufzeit. Viele Handyverträge haben eine Vertragslaufzeit von 2 Jahren, die man einhalten muss, z. B. vom 1.1.2019 bis zum 31.12.2021. Häufig verlängern sich die Verträge, wenn du nicht fristgerecht kündigst, in diesem Beispiel zum 30.9.2020. Trage dir diesen Zeitpunkt unbedingt im Kalender ein!',
        image: 'Vertragslaufzeit.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man den Schutz der personenbezogenen Daten?',
        difficulty: '1',
        title: 'Datenschutz',
        category: 'Handy',
        points: 1,
        explanation: 'In Deutschland ist vielen Menschen der Schutz der persönlichen Daten sehr wichtig. Persönliche Daten sind Informationen über dich, z. B.: Name, Adresse, Alter, Telefonnummer.\nDu entscheidest, wer diese Daten haben darf und wer nicht. Beim Laden von Apps wird häufig nach persönlichen Daten gefragt, die für die App nicht wichtig sind. Die Aufgabe des Datenschutzes ist, dich vor Missbrauch zu schützen.',
        image: 'Datenschutz.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn man ein Handy gekauft hat und es Monat für Monat abbezahlt?',
        difficulty: '1',
        title: 'Ratenkauf',
        category: 'Handy',
        points: 1,
        explanation: 'Wenn du ein Handy kaufst, kannst du es in Raten bezahlen. Du bezahlst jeden Monat einen festen Betrag. Am Ende der Ratenzahlung gehört das Handy dir.\nWie lange und wie viel du bezahlst, ist in einem Vertrag geregelt. Bevor du einen Ratenvertrag abschließt, prüfe bitte, ob du diese Rate auch regelmäßig bezahlen kannst.',
        image: 'Ratenkauf.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was bekommt man vom Verkäufer, wenn man etwas gekauft hat?',
        difficulty: '1',
        title: 'Rechnung',
        category: 'Handy',
        points: 1,
        explanation: 'Wenn du einen Vertrag mit einem Mobilfunkanbieter abschließt, bekommst du jeden Monat eine Rechnung.\nAuf der Rechnung stehen zum Beispiel:\n▪ der Name und die Adresse des Verkäufers,\n▪ der Preis der Leistung,\n▪ die Leistung oder die Menge der Waren,\n▪ das Datum der Rechnung,\n▪ die Bankverbindung des Verkäufers \n▪ und bis wann du die Rechnung bezahlen musst.',
        image: 'Rechnung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt ein Handyvertrag, bei dem man für einen festen Geldbetrag so lange telefonieren und im Internet surfen kann, wie man will?',
        difficulty: '1',
        title: 'Flatrate',
        category: 'Handy',
        points: 1,
        explanation: 'Flatrate ist ein englisches Wort und bedeutet „Pauschale“. Bei der Pauschale bezahlst du monatlich einen festen Betrag für eine Leistung.\nFür eine Flatrate, z. B. bei einem Handyvertrag, zahlst du eine bestimmte Geldsumme und kannst dafür so viel telefonieren und so lange im Internet surfen, wie du willst Prüfe beim Abschluss eines Vertrags genau, welche Leistungen in der Flatrate enthalten sind.',
        image: 'Flatrate.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was bezahlt man, wenn man sich Geld bei einer Bank leiht?',
        difficulty: '1',
        title: 'Zinsen',
        category: 'Konto',
        points: 1,
        explanation: 'Wenn du dir bei der Bank Geld leihst, dann musst du dafür Zinsen zahlen. Wie viel Zinsen du bezahlst, hängt davon ab,\n▪ wie viel Geld du dir leihst,\n▪ wie lange du das Geld leihen willst,\n▪ wie viel Geld du regelmäßig zurückbezahlen kannst,\n▪ was du von dem Geld kaufen willst.\nWenn du Geld auf einem Sparkonto oder einem Tagesgeldkonto sparst, dann bekommst du dafür oft Zinsen.',
        image: 'Zinsen.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt das Konto, das jeder in Deutschland eröffnen kann?',
        difficulty: '1',
        title: 'Basiskonto',
        category: 'Konto',
        points: 1,
        eexplanation: 'Das Basiskonto ist ein Konto, das du bei jeder Bank einrichten kannst. Mit dem Basiskonto kannst du:\n▪ Geld überweisen\n▪ Lastschriften einrichten\n▪ mit einer Girocard zahlen\n▪ Geld einzahlen und abheben\nDu musst ein Formular ausfüllen, um das Konto zu eröffnen. Und du musst deinen Ausweis vorzeigen.',
        image: 'Basiskonto.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die Bankkarte, mit der man überall auf der Welt bezahlen und im Internet einkaufen kann?',
        difficulty: '1',
        title: 'Kreditkarte',
        category: 'Konto',
        points: 1,
        explanation: 'Eine Kreditkarte kannst du beantragen, wenn du bei einer Bank ein Konto hast. Mit der Kreditkarte kannst du bargeldlos bezahlen. Wenn du mit der Kreditkarte bezahlst, leiht dir die Bank in der Regel das Geld für einen Monat im Voraus. Für manche Kreditkarten bezahlt man jährlich eine Gebühr. Beispiele für Kreditkarten sind:Eine Kreditkarte kannst▪ Mastercard\n▪ Visa\n▪ Diner\nv▪ American Express',
        image: 'Kreditkarte.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn man Geld von einem Konto auf ein anderes Konto überträgt?',
        difficulty: '1',
        title: 'Überweisung',
        category: 'Konto',
        points: 1,
        explanation: 'Bei einer Überweisung wird Geld von einem Girokonto auf das Girokonto eines anderen übertragen. Hierfür brauchst du die Kontoverbindung (IBAN) des Empfängers.Überweisungen kannst du mit einem Papierformular in der Bank oder im Internet machen. Wenn du im Internet etwas kaufst, kannst du für eine Vorauszahlung eine Überweisung tätigen oder den Onlinebezahldienst PayPal nutzen.',
        image: 'Ueberweisung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn man Geld von seinem Konto abhebt?',
        difficulty: '1',
        title: 'Auszahlung',
        category: 'Konto',
        points: 1,
        explanation: 'Wenn du Geld von deinem Konto abheben möchtest, bekommst du von der Bank Bargeld ausgezahlt. Geld kannst du dir bei der Bank oder an einem Bankautomaten mit einer Bankkarte und einer geheimen Nummer – der PIN – auszahlen lassen.\nBei der Auszahlung von Geld kann es sein, dass du Gebühren bezahlen musst. Dies kann passieren, wenn du dir Geld an einem Automaten auszahlen lässt, der nicht zu der Bank gehört, wo du dein Konto hast.',
        image: 'Auszahlung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die lange Nummer eines Kontos?',
        difficulty: '1',
        title: 'IBAN',
        category: 'Konto',
        points: 1,
        explanation: 'Jedes Konto hat eine eigene Nummer. Diese nennt man IBAN. Wenn dir jemand Geld auf dein Konto überweisen oder jemand Geld von deinem Konto abbuchen will, braucht er deine IBAN.\nDie IBAN ist eine international gültige Nummer. Eine deutsche IBAN besteht aus dem Länderkennzeichen DE, einer zweistelligen Prüfzahl, einer achtstelligen Bankleitzahl und einer zehnstelligen Kontonummer.',
        image: 'IBAN.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn man jemandem erlaubt, Geld von seinem Konto abzubuchen?',
        difficulty: '1',
        title: 'Einzugsermächtigung',
        category: 'Konto',
        points: 1,
        explanation: 'Mit einer Einzugsermächtigung (= Lastschriftverfahren) erlaubst du jemand anderem, Geld von deinem Konto abzubuchen. Das können zum Beispiel Versicherungsbeiträge sein.\nFür eine Einzugsermächtigung musst du ein Formular ausfüllen und unterschreiben. Eine Einzugsermächtigung kannst du jederzeit schriftlich widerrufen.\nDenke daran, dass genügend Geld für den Abbuchungsbetrag auf dem Konto sein muss!',
        image: 'Einzugsermaechtigung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die Karte, mit der man in einem Geschäft ohne Bargeld bezahlen kann?',
        difficulty: '1',
        title: 'Girocard',
        category: 'Konto',
        points: 1,
        explanation: 'Die Girocard ist eine Bankkarte, die häufig auch EC-Karte genannt wird. Eine Girocard bekommst du, wenn du ein Konto bei einer Bank hast.\nMit der Girocard kannst du in einem Geschäft ohne Bargeld bezahlen oder am Bankautomaten Geld von deinem Konto abheben\nDie Girocard ist mit deinem Girokonto verbunden. Zahlungen werden ein bis drei Tage später von deinem Konto abgebucht.',
        image: 'Girocard.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was muss man regelmäßig bezahlen, wenn man in einer Wohnung wohnt, die einem nicht gehört?',
        difficulty: '1',
        title: 'Miete',
        category: 'Wohnung',
        points: 1,
        explanation: 'Wenn du in einer Wohnung wohnst, die dir nicht gehört, dann zahlst du regelmäßig Miete an den Vermieter.ie viel Miete du bezahlen musst, steht im Mietvertrag. Möchtest du aus der Wohnung ausziehen, musst du kündigen. Beim Einzug zahlst du eine einmalige Kaution. Wenn die Wohnung beim Auszug in Ordnung ist und keine Schäden hat, bekommst du die Kaution zurück.',
        image: 'Miete.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was unterschreibt man, wenn man eine Wohnung mieten möchte?',
        difficulty: '1',
        title: 'Mietvertrag',
        category: 'Wohnung',
        points: 1,
        explanation: 'Wenn du eine Wohnung mieten möchtest, musst du einen Mietvertrag unterschreiben. Im Mietvertrag steht,\n▪ wie hoch die Miete ist,\n▪ wann die Miete gezahlt werden muss,\n▪ wie lange der Vertrag gültig ist.\nLies den Vertrag genau durch und prüfe, ob alle Angaben richtig sind. Wenn du unsicher bist, kannst du dir Hilfe beim Mieterverein holen.',
        image: 'Mietvertrag.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißen die Kosten in der`Gesamtmiete, die man z. B. für heißes Wasser, den Aufzug und die Müllbeseitigung bezahlt?',
        difficulty: '1',
        title: 'Betriebskosten',
        category: 'Wohnung',
        points: 1,
        explanation: 'Neben der Kaltmiete bezahlst du Betriebskosten, z. B. für warmes Wasser, Müll oder Heizung. Eine genaue Abrechnung hierfür bekommst du vom Vermieter spätestens im Dezember des nächsten Jahres.Hast du viel warmes Wasser verbraucht oder im Winter viel geheizt, kann es sein, dass du nachzahlen musst. Daher ist es ratsam, für die Betriebskosten zusätzlich etwas Geld zurückzulegen \nKaltmiete und Betriebskosten ergeben die Warmmiete.',
        image: 'Betriebskosten.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn sich mehrere Menschen eine Wohnung teilen, um Kosten zu sparen?',
        difficulty: '1',
        title: 'Wohngemeinschaft',
        category: 'Wohnung',
        points: 1,
        explanation: 'Wenn sich mehrere Menschen eine Wohnung teilen, spricht man von einer Wohngemeinschaft – Abkürzung WG.\nDas ist eine praktische Lösung, wenn du dir keine eigene Wohnung leisten kannst. Denn die Bewohner einer Wohngemeinschaft teilen sich die Kosten (z. B. für Miete und Strom).\nAlle Personen, die im Mietvertrag stehen, haften für die Zahlung der Gesamtmiete.',
        image: 'Wohngemeinschaft.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man die Person, die eine Wohnung vermietet?',
        difficulty: '1',
        title: 'Vermieter',
        category: 'Wohnung',
        points: 1,
        explanation: 'Der Vermieter kann der Eigentümer einer Wohnung sein. Häufig werden Wohnungen auch von Wohnungsverwaltungen im Namen des Eigentümers vermietet – zum Beispiel, wenn der Vermieter nicht am gleichen Ort wohnt oder die Wohnungen der Stadt oder Gemeinde gehören.',
        image: 'Vermieter.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was bekommt man, wenn die Miete oder eine Rechnung nicht pünktlich bezahlt wird?',
        difficulty: '1',
        title: 'Mahnung',
        category: 'Wohnung',
        points: 1,
        explanation: 'Wenn du deine Miete oder sonstige Rechnungen nicht rechtzeitig bezahlst, bekommst du eine Mahnung. Damit sollst du daran erinnert werden, deine Rechnung zu bezahlen.\nFür die Mahnung können Gebühren erhoben werden, die du dann zusätzlich zahlen musst. Wenn du trotzdem nicht zahlst, kommt es im schlimmsten Fall zu einer Zwangsvollstreckung. Dann können dein Lohn oder Gehalt, deine Konten oder dein Eigentum gepfändet werden.',
        image: 'Mahnung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Manche Vermieter möchten einen Nachweis, dass man in der Lage ist, Rechnungen immer pünktlich zu bezahlen. Wie nennt man diesen Nachweis?',
        difficulty: '1',
        title: 'Bonitätsauskunft',
        category: 'Wohnung',
        points: 1,
        explanation: 'Die Bonitätsauskunft zeigt, ob du deine Rechnungen und Kredite zuverlässig bezahlt hast. Eine Bonitätsauskunft brauchst du zum Beispiel,\n▪ wenn du eine Wohnung mieten möchtest,\n▪ wenn du einen Arbeitsvertrag unterschreiben willst.\nEine Bonitätsauskunft bekommst du zum Beispiel bei der SCHUFA (Schutzgemeinschaft für allgemeine Kreditsicherung).',
        image: 'Bonitaetsauskunft.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was braucht man, wenn eine andere Person im Notfall eine Schuld oder eine Rechnung bezahlen soll?',
        difficulty: '1',
        title: 'Bürgschaft',
        category: 'Wohnung',
        points: 1,
        explanation: 'Ein Vermieter will sicher sein, dass er regelmäßig die Miete bekommt. Darum verlangen manche Vermieter eine Bürgschaft.\nFür eine Bürgschaft musst du eine Person finden, die sich verpflichtet, für dich die Miete zu zahlen, wenn du es nicht tust.',
        image: 'Buergschaft.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wonach richtet sich die Höhe der Steuer, die vom Lohn abgezogen wird?',
        difficulty: '2',
        title: 'Steuerklasse',
        category: 'Arbeit',
        points: 2,
        explanation: 'Wenn du Arbeitnehmer bist, bestimmt die Lohnsteuerklasse, wie viel Lohnsteuer, Solidaritätszuschlag und Kirchensteuer dir von deinem Lohn oder Gehalt abgezogen wird.\nEs gibt sechs Lohnsteuerklassen, die vor allem vom Familienstand abhängig sind, zum Beispiel wenn du verheiratet bist oder Kinder hast.\nWenn sich die Voraussetzungen für die Steuerklasse ändern (z. B. wenn du geheiratet oder ein Kind bekommen hast), musst du das dem Finanzamt mitteilen.',
        image: 'Steuerklasse.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie kann sich ein Gläubiger Geld beim Arbeitgeber holen, wenn man seine Schulden nicht zahlt?',
        difficulty: '2',
        title: 'Lohnpfändung',
        category: 'Arbeit',
        points: 2,
        explanation: 'Wenn du deine Schulden nicht bezahlst, kann sich ein Gläubiger sein Geld mithilfe des Gerichts direkt von deinem Arbeitgeber holen. Das nennt man Lohnpfändung. Der Arbeitgeber darf aber nicht deinen ganzen Lohn überweisen.\nDenn jeder Mensch hat das Recht auf eine bestimmte Menge Geld. Diese Summe heißt Existenzminimum. Deshalb legt der Gesetzgeber Pfändungsfreigrenzen für Arbeitseinkommen fest.',
        image: 'Lohnpfaendung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was braucht das Finanzamt, um festzustellen, ob du Steuern erstattet bekommst oder nachzahlen musst?',
        difficulty: '2',
        title: 'Steuererklärung',
        category: 'Arbeit',
        points: 2,
        explanation: 'Du reichst beim Finanzamt die Einkommensteuererklärung ein. Hier gibst du an, wie viel du im vergangenen Jahr verdient hast. Du kannst bestimmte Ausgaben und Freibeträge abziehen. Das Finanzamt berechnet daraus die Einkommensteuer.\nHast du über das Jahr verteilt zu viel Steuern vorausgezahlt, bekommst du Geld zurück. Hast du zu wenig gezahlt, musst du Steuern nachzahlen.\nUnterstützung erhältst du bei Lohnsteuerhilfevereinen oder bei Steuerberatern.',
        image: 'Steuererklaerung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die Versicherung, bei der man einzahlt, um nach Ende des Arbeitslebens Rente zu bekommen?',
        difficulty: '2',
        title: 'Rentenversicherung',
        category: 'Arbeit',
        points: 2,
        explanation: 'Jeder Arbeitnehmer zahlt automatisch in die gesetzliche Rentenversicherung ein. Das Geld wird vom Lohn abgezogen. Wenn du 67 Jahre alt bist und mindestens fünf Jahre versichert warst, bekommst du Rente.\nDie Deutsche Rentenversicherung schickt dir jedes Jahr eine Übersicht, wie hoch deine Rente voraussichtlich sein wird.',
        image: 'Rentenversicherung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die Versicherung, die einen Arbeitnehmer bei Krankheit, Arbeitslosigkeit oder Rente absichert?',
        difficulty: '2',
        title: 'Sozialversicherung',
        category: 'Arbeit',
        points: 2,
        explanation: 'Die Sozialversicherung ist eine staatliche Pflichtversicherung zum Schutz der Arbeitnehmer.\nDie Kosten werden von allen Versicherten getragen. Die Sozialversicherung bezahlt bei:\n▪ Krankheit \n▪ Unfall \n▪ Rente \n▪ Pflege \n▪ Arbeitslosigkeit',
        image: 'Sozialversicherung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Welche finanzielle Unterstützung kann man als Student oder Schüler beantragen?',
        difficulty: '2',
        title: 'BAföG',
        category: 'Arbeit',
        points: 2,
        explanation: 'Wenn du studieren willst oder nach der 10. Klasse noch einen weiteren Schulabschluss machen möchtest und dir hierfür das Geld fehlt, kannst du BAföG (Bundesausbildungsförderungsgesetz) beantragen.\nAls Student stellst du beim zuständigen Studentenwerk einen Antrag. Studenten müssen die Hälfte des BAföG später zurückzahlen, maximal jedoch 10.000 Euro.\nOb du BAföG bekommst, hängt aber auch vom Einkommen deiner Eltern ab.',
        image: 'BAFOEG.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wer bezahlt die Kosten, wenn man zum Arzt oder ins Krankenhaus geht?',
        difficulty: '2',
        title: 'Krankenversicherung',
        category: 'Arbeit',
        points: 2,
        explanation: 'In Deutschland muss jeder krankenversichert sein. Die meisten Menschen sind in einer gesetzlichen Krankenversicherung (GKV) pflichtversichert.\nDie Höhe des Beitrags hängt vom Einkommen ab. Arbeitgeber und Arbeitnehmer teilen sich die Beiträge.\nVon der Krankenkasse bekommst du eine Versichertenkarte, die du beim Arzt oder im Krankenhaus vorzeigen musst.',
        image: 'Krankenversicherung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man eine Beschäftigung, bei der man nicht mehr als 450 Euro im Monat verdienen darf?',
        difficulty: '2',
        title: 'Minijob',
        category: 'Arbeit',
        points: 2,
        explanation: 'Ein Minijob ist eine geringfügige Beschäftigung, bei der du monatlich maximal 450 Euro verdienen darfst.\nAuch eine kurze Beschäftigung, die maximal drei Monate oder 70 Arbeitstage dauert, ist ein Minijob.\nBis auf einen Anteil zur gesetzlichen Rentenversicherung ist ein Minijob für dich sozialversicherungs- und steuerfrei. Das heißt, du verdienst dann netto so viel wie brutto.',
        image: 'Minijob.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt das Recht, mit dem man einen Vertrag rückgängig machen kann, der online abgeschlossen wurde?',
        difficulty: '2',
        title: 'Widerrufsrecht',
        category: 'Handy',
        points: 2,
        explanation: 'Wenn du etwas online gekauft oder einen Vertrag abgeschlossen hast, kannst du das rückgängig machen. Das Widerrufsrecht ermöglicht es dir, Verträge ohne die Angabe von Gründen rückgängig zu machen.\nDie Widerrufsfrist beträgt in der Regel 14 Tage, nachdem du deine Ware erhalten hast. Achte darauf, den Widerruf schriftlich zu formulieren und fristgerecht abzusenden. Nenne dazu deinen Namen, deine Anschrift, Kunden und Vertragsnummer und denke an deine Unterschrift. Bitte immer um eine schriftliche Bestätigung.',
        image: 'Widerrufsrecht.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was leistet man, wenn man etwas kaufen möchte, aber erst einmal nur einen Teil des Kaufpreises bezahlt?',
        difficulty: '2',
        title: 'Anzahlung',
        category: 'Handy',
        points: 2,
        explanation: 'Normalerweise kaufst du etwas und bezahlst es sofort. Wenn du aber beispielsweise eine Reise buchst und nicht gleich den gesamten Kaufpreis bezahlst, sondern nur einen Teil, dann nennt man das eine Anzahlung. Lass dir dafür eine Quittung geben.Die Anzahlung ist die erste Rate des Kaufpreises und gibt dem Verkäufer die Sicherheit, dass er zumindest einen Teil des Kaufpreises bekommt. Den Rest bezahlst du je nach Vereinbarung in einer Summe oder in monatlichen Raten.',
        image: 'Anzahlung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was braucht man, damit man mit dem Handy im Internet surfen oder Nachrichten verschicken kann?',
        difficulty: '2',
        title: 'Datenvolumen',
        category: 'Handy',
        points: 2,
        explanation: 'Wenn du mit dem Handy das Internet oder Messenger-Dienste nutzen möchtest, brauchst du Datenvolumen.\nEinen Vertrag für Datenvolumen schließt du mit einem Mobilfunkanbieter ab. In diesem Vertrag wird geregelt,\n▪ wie viel Datenvolumen (zum Beispiel: Gigabyte) du nutzen kannst,\n▪ wie viel du dafür bezahlen musst.',
        image: 'Datenvolumen.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was sollte man gelesen haben, bevor man einen Vertrag abschließt?',
        difficulty: '2',
        title: 'AGB',
        category: 'Handy',
        points: 2,
        explanation: 'AGB bedeutet Allgemeine Geschäftsbedingungen. In den AGB stehen vorformulierte Bedingungen von Verträgen, die das Unternehmen abschließt. Zum Beispiel:\n▪ Zahlungsbedingungen\n▪ Widerrufmöglichkeiten\n▪ Vertragslaufzeiten\n▪ Datenschutzbestimmungen\n▪ Kündigungsfristen\n\nDie AGB werden als „das Kleingedruckte“ bezeichnet, weil sie oft sehr klein gedruckt und sehr lang sind. Dennoch ist es wichtig, die AGB zu lesen, bevor man den Vertrag schließt.',
        image: 'AGB.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn man die SIM-Karte im Ausland nutzt?',
        difficulty: '2',
        title: 'Roaming',
        category: 'Handy',
        points: 2,
        explanation: 'Nutzt du mit dem Handy, Tablet oder einem anderen mobilen Endgerät das Netz im Ausland, spricht man von Roaming.\nWenn du beispielsweise im Ausland mit deinem Handy ins Internet gehst, fallen Roaminggebühren an, die sehr hoch sein können. Diese Kosten können auch dann entstehen, wenn du im Ausland bist und dich jemand\nanruft.\nInnerhalb der EU fallen jedoch in der Regel keine zusätzlichen Gebühren an. Das solltest du aber in jedem Fall prüfen.',
        image: 'Roaming.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man es, wenn man im Internet einkauft?',
        difficulty: '2',
        title: 'Online-Kauf',
        category: 'Handy',
        points: 2,
        explanation: 'Wenn du etwas im Internet kaufst, nennt man das Online- Kauf. Online-Käufe, die dir nicht gefallen, kannst du in der Regel innerhalb von 14 Tagen zurückgeben.\nVorsicht bei Sendungen aus dem Ausland. Hier können Einfuhrsteuern und Zollgebühren verlangt werden. Das gilt auch für Geschenke. Sei bei Online-Käufen immer vorsichtig. Manche Verkäufer verlangen das Geld im Voraus, senden die Ware aber nicht wie vereinbart zu. Das ist dann Betrug.',
        image: 'Online-Kauf.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wovon hängt es ab, wie viel Datenvolumen auf dem Handy zur Verfügung steht?',
        difficulty: '2',
        title: 'Mobilfunktarif',
        category: 'Handy',
        points: 2,
        explanation: 'Wenn du mit einem Handy telefonieren, Nachrichten schreiben oder das Internet nutzen möchtest, wählst du einen Mobilfunktarif, der zu dir passt.\nDazu schließt du einen Vertrag mit einem Mobilfunkanbieter ab.\nEs gibt viele verschiedene Möglichkeiten und Angebote. Du solltest vorher überlegen, was du am meisten nutzt, und dann die Preise der Anbieter vergleichen.',
        image: 'Mobilfunktarif.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was benötigt man, wenn man in seiner Wohnung ein Telefon haben möchte?',
        difficulty: '2',
        title: 'Festnetzanschluss',
        category: 'Handy',
        points: 2,
        explanation: 'Wenn du zu Hause ein festes Telefon haben möchtest, kannst du einen Vertrag über einen Festnetzanschluss abschließen. Dann bekommst du wie für dein Handy eine eigene Telefonnummer. Oft wird der Festnetzanschluss\nzusammen mit einem Internet- oder Kabelanschluss angeboten. Vergleiche vorher die unterschiedlichen Preise.',
        image: 'Festnetzanschluss.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was gibt die Bank, wenn sie einem Kunden für eine bestimmte Zeit Geld verleiht?',
        difficulty: '2',
        title: 'Darlehen',
        category: 'Konto',
        points: 2,
        explanation: 'Wenn du dir Geld bei einer Bank leihen möchtest (einen Kredit aufnehmen), gibt sie dir ein Darlehen. Die Bank prüft vorher, ob du in der Lage bist, dieses Darlehen zurückzuzahlen, z. B. indem du ein regelmäßiges Einkommen nachweist.\nIn einem Kreditvertrag stehen die Summe des Darlehens, der Tag der Auszahlung, der Zinssatz, die Laufzeit sowie die Vereinbarungen zur Rückzahlung. Überlege vorher gut, ob du jeden Monat genug Geld für die Zahlung der Kreditrate übrig hast.',
        image: 'Darlehen.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was richtet man bei der Bank ein, wenn man regelmäßig den gleichen Geldbetrag überweisen will?',
        difficulty: '2',
        title: 'Dauerauftrag',
        category: 'Konto',
        points: 2,
        explanation: 'Mit einem Dauerauftrag kannst du regelmäßig von deinem Konto einen bestimmten Betrag auf ein anderes Konto überweisen. So vergisst du nicht, regelmäßig wiederkehrende Überweisungen wie Miete oder Raten zu bezahlen. Wichtig ist, dass der Betrag und das Ausführungsdatum immer gleich sind. Ein Dauerauftrag kann monatlich, viertel- und halbjährlich oder jährlich ausgeführt werden.',
        image: 'Dauerauftrag.svg',
        active: false,
        played: false
      },
      {
        msg: 'Welchen Kredit erhält man von der Bank, wenn man mehr Geld ausgeben kann, als auf dem Konto ist?',
        difficulty: '2',
        title: 'Dispositionskredit',
        category: 'Konto',
        points: 2,
        explanation: 'Die Bank kann dir ermöglichen, dein Girokonto bis zu einem festgelegten Betrag zu überziehen. So kannst du Geld abheben oder überweisen, auch wenn du kein Guthaben hast. Das nennt sich Dispositionskredit (Dispo).\nDu bekommst einen Dispositionskredit nur, wenn monatlich ein Gehalt oder eine Rente auf deinem Konto eingeht. Für den Dispositionskredit zahlst du hohe Zinsen. Daher solltest du darauf achten, dein Konto nur im Notfall zu überziehen.',
        image: 'Dispositionskredit.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was benötigt man, um Zahlungen ohne Bargeld durchzuführen?',
        difficulty: '2',
        title: 'Girokonto',
        category: 'Konto',
        points: 2,
        explanation: 'Der Name Girokonto leitet sich vom italienischen Wort „giro“ab, das „Kreis/Umlauf“ bedeutet. Ein Girokonto kannst du bei jeder\nBank einrichten. Ein Girokonto brauchst du für:\n▪ Gehaltszahlungen oder Sozialleistungen\n▪ bargeldloses Bezahlen\n▪ Ein- oder Auszahlungen in bar oder am Geldautomaten\n▪ Überweisung/Dauerauftrag\nViele Banken verlangen Kontoführungsgebühren für ein Girokonto.',
        image: 'Girokonto.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was muss man in manchen Fällen bezahlen, wenn man ein Konto bei einer Bank hat?',
        difficulty: '2',
        title: 'Kontoführungsgebühr',
        category: 'Konto',
        points: 2,
        explanation: 'Die Bank stellt dir ein Konto zur Verfügung, damit du Bankgeschäfte durchführen kannst. Bei einigen Banken ist das kostenlos. Andere verlangen Kontoführungsgebühren für ihre Arbeit. Diese können bei jeder Bank unterschiedlich sein. Mit deiner Unterschrift auf dem Kontovertrag akzeptierst du eventuell anfallende Gebühren.\nInformiere dich vorher, welche Banken ein kostenloses Girokonto anbieten, und prüfe die Bedingungen.',
        image: 'Kontoführungsgebuehr.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was macht man, um den Überblick über die Einnahmen und Ausgaben zu behalten?',
        difficulty: '2',
        title: 'Haushaltsplan',
        category: 'Konto',
        points: 2,
        explanation: 'In einen Haushaltsplan trägst du auf einer Seite alle monatlichen Einnahmen wie Ausbildungsvergütung oder Lohn ein.\nAuf der anderen Seite notierst du die monatlichen Ausgaben wie Miete, Strom und Lebensmittel. Dann ziehst du die Ausgaben von den Einnahmen ab. Bei einem „Plus“ kannst du das Geld zurücklegen, z. B. für einen Urlaub. Ein „Minus“ bedeutet, dass du mehr Geld ausgibst, als du hast.',
        image: 'Haushaltsplan.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie kann sich ein Gläubiger Geld von Bankkonten holen, wenn man seine Schulden nicht zahlt?',
        difficulty: '2',
        title: 'Kontenpfändung',
        category: 'Konto',
        points: 2,
        explanation: 'Wenn du deine Schulden nicht bezahlst, kann sich ein Gläubiger sein Geld mithilfe des Gerichts von deinen Bankkonten holen. Dies nennt man Kontenpfändung. Dann wird z. B. dein Girokonto gesperrt und du kannst nicht mehr über dein Geld verfügen. Sprich mit deiner Bank über ein Pfändungsschutzkonto (P-Konto), sonst überweist die Bank nach der Schutzfrist von 14 Tagen dem Gläubiger das verlangte Geld.',
        image: 'Kontenpfaendung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Worauf kann man den Kontostand ablesen?',
        difficulty: '2',
        title: 'Kontoauszug',
        category: 'Konto',
        points: 2,
        explanation: 'Auf einem Kontoauszug werden alle Einzahlungen und Auszahlungen deines Kontos aufgeführt. Deine Kontoauszüge kannst du in \nder Bank am Automaten drucken, dir per Post zuschicken lassen oder online als PDF abrufen.\nÜberprüfe regelmäßig die Abbuchungen und Einzahlungen auf deinem Konto sowie den Kontostand.',
        image: 'Kontoauszug.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was kann man beantragen, wenn die Miete im Vergleich zum Einkommen zu hoch ist?',
        difficulty: '2',
        title: 'Wohngeld',
        category: 'Wohnung',
        points: 2,
        explanation: 'Wenn du ein geringes Einkommen hast, kannst du bei der Wohngeldbehörde einen Antrag auf Wohngeld stellen. Wohngeld ist ein Zuschuss zur Miete. Die Höhe hängt davon ab,\n▪ wie hoch das Einkommen ist,\n▪ wie hoch die Miete ist,\n▪ wie viele andere Personen im\nHaushalt leben und wie hoch deren Einkommen ist. Wenn du Arbeitslosengeld II bekommst, kannst du keinen Antrag auf Wohngeld stellen. Die Kosten für Wohnung und Heizung werden dann schon für dich bezahlt.',
        image: 'Wohngeld.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man ein Unternehmen, das Informationen über deine finanzielle Zuverlässigkeit weitergibt?',
        difficulty: '2',
        title: 'Auskunftei',
        category: 'Wohnung',
        points: 2,
        explanation: 'Eine Auskunftei stellt Informationen zur finanziellen Zuverlässigkeit von Privatpersonen und Unternehmen bereit und gibt diese beispielsweise an Banken oder Online-Händler weiter. Die SCHUFA – Schutzgemeinschaft für allgemeine Kreditsicherung – ist eine Auskunftei. Hier kannst du auch eine Bonitätsauskunft z. B. für deinen Vermieter kostenpflichtig bestellen.\nUm Auskunft über zu dir gespeicherte Daten zu erhalten, kannst du kostenlos eine Kopie der personenbezogenen Daten (nach Art. 15 DSGVO) anfordern.',
        image: 'Auskunftei.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man die Zahlung, die man für Strom oder Gas monatlich im Voraus zahlen muss?',
        difficulty: '2',
        title: 'Abschlagszahlung',
        category: 'Wohnung',
        points: 2,
        explanation: 'Bei Abschlagszahlungen zahlst du regelmäßig einen vorher festgelegten Betrag. Dieser wird dann mit der Endabrechnung abgeglichen. Ein Beispiel: Du bezahlst jeden Monat 40,00 Euro an den Stromanbieter. Der liest einmal im Jahr den Stromverbrauch in der Wohnung oder im Haus ab und erstellt eine Endabrechnung über den tatsächlich verbrauchten Strom. Du bekommst dann Geld zurück oder musst eine Nachzahlung leisten.',
        image: 'Abschlagszahlung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man elektrische Geräte, die besonders viel Strom verbrauchen?',
        difficulty: '2',
        title: 'Stromfresser',
        category: 'Wohnung',
        points: 2,
        explanation: 'Stromfresser heißen umgangssprachlich elektrische Geräte, die viel Strom verbrauchen. Dazu gehören beispielsweise alte Kühlschränke, Durchlauferhitzer, Spülmaschinen oder elektrische Heizungen. Achte beim Kauf darauf, dass du möglichst Geräte auswählst, die einen niedrigen Stromverbrauch haben. Diese Information steht auf den Geräten. Je mehr Strom du verbrauchst,\ndesto höhere Kosten entstehen.',
        image: 'Stromfresser.svg',
        active: false,
        played: false
      },
      {
        msg: 'Was verlangen viele Vermieter als Sicherheit, bevor man in eine Wohnung einzieht?',
        difficulty: '2',
        title: 'Mietkaution',
        category: 'Wohnung',
        points: 2,
        explanation: 'Viele Vermieter möchten eine Mietkaution, bevor man in die Wohnung einziehen kann. Die Mietkaution beträgt maximal drei Kaltmieten. Dieses Geld behält der Vermieter zur Sicherheit. Wenn du zum Beispiel nach dem Auszug Schäden in der Wohnung hinterlässt, zieht der Vermieter die Reparatur von der Mietkaution ab.\nDeine Mietkaution legt der Vermieter z. B. auf einem Sparbuch an. Gibt es beim Auszug nichts zu beanstanden, erhältst du dein Geld mit Zinsen zurück.',
        image: 'Mietkaution.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt der Beitrag, den jeder Haushalt in Deutschland für den Empfang von ARD, ZDF und Deutschlandradio bezahlen muss?',
        difficulty: '2',
        title: 'Rundfunkbeitrag',
        category: 'Wohnung',
        points: 2,
        explanation: 'Den Rundfunkbeitrag muss jeder Haushalt in Deutschland zahlen. Damit wird das Programm der öffentlich- rechtlichen Fernseh- und Hörfunksender wie ARD, ZDF und Deutschlandradio bezahlt. Für jede Wohnung beträgt der Beitrag 17,50 Euro pro Monat. Wenn du Arbeitslosengeld II oder BAföG bekommst, kannst du dich jeweils für ein Jahr vom Rundfunkbeitrag befreien lassen. Du musst jedes Jahr einen neuen Antrag stellen. Das Formular kann man online auf der Webseite ausfüllen: www.rundfunkbeitrag.de.',
        image: 'Rundfunkbeitrag.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie heißt die Versicherung, die bei Wasser, Feuer oder Einbruch in einer Wohnung den Schaden ersetzt?',
        difficulty: '2',
        title: 'Hausratversicherung',
        category: 'Wohnung',
        points: 2,
        explanation: 'Die Hausratversicherung zahlt den Schaden, wenn Gegenstände in deiner Wohnung durch Wasser oder Feuer beschädigt oder zerstört worden sind. Oder wenn bei einem Einbruch Gegenstände gestohlen wurden.\nMelde sofort deiner Versicherung, was zerstört, beschädigt oder gestohlen worden ist. Hebe daher deine Rechnungen auf, damit du nachweisen kannst, was die Gegenstände gekostet haben. Bei einem Einbruch musst du auch die Polizei verständigen.',
        image: 'Hausratversicherung.svg',
        active: false,
        played: false
      },
      {
        msg: 'Wie nennt man das Protokoll, das man bei einer Wohnungsübergabe bekommt?',
        difficulty: '2',
        title: 'Übergabeprotokoll',
        category: 'Wohnung',
        points: 2,
        explanation: 'Wenn du in eine Wohnung einziehst oder aus einer Wohnung ausziehst, schreibt dein Vermieter alle Mängel oder Schäden in einem Übergabeprotokoll auf. Auch wenn alles in Ordnung ist, steht das im Protokoll. Mit deiner Unterschrift bestätigst du die Übergabe oder Übernahme der Wohnung. Du bekommst ein Exemplar des Übergabeprotokolls. Nimm bei einer Wohnungsübergabe am besten einen Zeugen mit.',
        image: 'Uebergabeprotokoll.svg',
        active: false,
        played: false
      },

    ],
  },
  getters: {
    getCards(state) {
      return state.currentCardStack;
    },
    getCurrentQuestion(state) {
      return state.currentCard.msg;
    },

    getCurrentCard(state) {
      return state.currentCard;
    },

    getCardsShuffled(state) {
      return function (difficulty) {
        console.log('shuffle cards');
        let cardsStackLength = state.cardStack.length
        let cardsArray = [];

        for (let i = 0; i < cardsStackLength; i++) {
          if (state.cardStack[i].difficulty == difficulty || difficulty == 3) {
            cardsArray.push(i);
          }
        }

        for (var i = cardsArray.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1)); //random index
          [cardsArray[i], cardsArray[j]] = [cardsArray[j], cardsArray[i]]; // swap
        }
        return cardsArray.toString();
      }
    }


  },
  mutations: {

    createCurrentCardStack(state, cards) {
      state.currentCardStack = [];
      cards = cards.split(',');
      console.log('going trough cards ' + cards.length);
      for (let i = 0; i < cards.length; i++) {
        let card = state.cardStack[cards[i]];
        card.active = false;
        state.currentCardStack.push(card);
      }
    },

    clear(state) {
      state.cardStack.forEach((v) => {
        console.log('clear card');
        v.active = false;
        v.played = false;
      })
    },
    addCardMut(state, newCard) {
      // mutate state
      state.cardStack.push(newCard);
    },
    activateCards(state, till) {
      console.log('activate cards:'+till);
      for (let i = (till-2); i <= (till+2); i++) {0
        if(state.currentCardStack[i]){

          if(i==(till-1)){
            state.currentCard = state.currentCardStack[i];
          }

          state.currentCardStack[i].active = true;
          if(i<till){
            state.currentCardStack[i].played = true;
          }
        }
      }
    }
  },
  actions: {
    clear({commit}: any) {
      commit('clear');
    },
    addCard(context, card) {
      context.commit('addCardMut', card);
    },
    activateCardsForRound(context, currentCard) {
      context.commit('activateCards', currentCard);
    },
    createCurrentCardStack(context, cards) {
      context.commit('createCurrentCardStack', cards);
    }

  },
};
