
import {Vue} from 'vue-class-component';
import {SocketApi} from "@/data/socket/Socket";

export default class Resultvote extends Vue {
  foobar = "";

  get Answers() {
    let AnswerList = this.$store.getters["playerList/getAnswers"];
    return AnswerList;
  }


  get currentQuestion() {
    let currentQuestion = this.$store.getters["cardstore/getCurrentQuestion"];
    return currentQuestion;
  }


  get gameMode() {
    return this.$store.getters["gameEnv/getGameMode"];
  }

  public addPoints() {
    const store = this.$store;
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    let rightSocks: String[] = [];
    checkboxes.forEach(input => {
      let inp = <HTMLInputElement>input;

      if (inp.checked) {
        let vals: String[] = inp.value.split(', ');
        rightSocks = rightSocks.concat(vals);
      }
    });


    //rightSocks.forEach(function (value) {
      let currentCard = store.getters["cardstore/getCurrentCard"];
      store.dispatch("gameEnv/addPoints", {points: currentCard.points, socketIds: rightSocks});
    //});
    SocketApi.getInstance().send("change_status", "roundEnd");
    store.dispatch("gameEnv/nextRound");
    //this.$store.dispatch("gameEnv/nextCard");
  }


}
