
import { computed } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { SocketApi } from './data/socket/Socket';
import { ComponentCustomProperties } from 'vue'
import { Store } from 'vuex'
import card from './components/card.vue';
import settings from "@/components/settings.vue";
import login from "./components/login.vue";
import createroom from "./components/createroom.vue";
import User from "./components/user.vue";
import Results from "@/components/results.vue";
import Guide from "@/components/guide.vue";

import Clock from "@/components/clock.vue";
import Gameinfo from "@/components/gameinfo.vue";
import Alert from "@/components/alert.vue";
import store from "@/store";
import Answer from "@/components/answer.vue";
import resultvote from "@/components/resultvote.vue";
import resultview from "@/components/resultview.vue";

declare module '@vue/runtime-core' {
  // declare your own store states
  interface State {
    count: number
  }

  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>
  }
}

require('./assets/fontawesome-pro-5.12.0-web/css/fontawesome.min.css');
require('./assets/fontawesome-pro-5.12.0-web/css/light.css');

@Options({
  components: {
    Alert,
    Gameinfo,
    Clock,
    card,
    login,
    settings,
    createroom,
    User,
    Results,
    Guide,
    Answer,
    resultvote,
    resultview

  },
})
export default class App extends Vue {
  gameStarted = false;
  showLogin = false;
  showCreate = false;
  resultMode = false;
  showAnswer = true;

  loginRoom(){
    this.showLogin = true;
  }

  creatRoom(){
    this.showCreate = true;
  }

  logout() {
    this.$store.dispatch("socketStore/logout");
  }

  public startTimer(){
    SocketApi.getInstance().send("change_status","questionAnswered");
    store.dispatch("gameEnv/actionMade");
    this.$store.dispatch("gameEnv/startTimer");
  }
  public stopTimer(){
    store.dispatch("gameEnv/actionMade");
    this.$store.dispatch("gameEnv/stopTimer");
  }

  public endRound(){
    store.dispatch("gameEnv/actionMade");
    SocketApi.getInstance().send("change_status","answerview");
  }

  copy() {

    const input = this.$refs.myinput as HTMLInputElement | null;

    if (input != null) {
      input.select();
    }

    document.execCommand('copy');
    input.selectionStart = input.selectionEnd;
    store.dispatch("socketStore/errorMessage", {code:0,message:"Text wurde kopiert"});
    input.blur();

  }


  created() {
    this.$store.dispatch("socketStore/checkReConnect")
  }

  get InvitationText(){
    return "Lust auf eine Runde Finanz-ABC?\n \n Um gemeinsam spielen zu können, gehe auf: "+window.location + " und gib unter \"Raum betreten\" folgenden Raumcode ein: "+this.roomName;
  }

  get alertMessage() {
    return this.$store.getters["socketStore/getErrorMessage"];
  }

  get Gamestatus(){
    return this.$store.getters['gameEnv/getStatus'];
  }

  get timerRunning(){
    if(this.$store.getters["gameEnv/getRound"] == 0) return false;
    if(this.$store.getters["gameEnv/getTimeString"]=="00:00"){
      return false;
    };

    return true;
  }

  get inRoom() {
    return this.$store.getters["gameEnv/isInRoom"];
  }

  get gameMode() {
    return this.$store.getters["gameEnv/getGameMode"];
  }

  get currentView(){
    return this.$store.getters["gameEnv/getCurrentView"];
  }

  get roomName(){
    return this.$store.getters["gameEnv/getRoomName"];
  }

  get currentRound(){
    return this.$store.getters["gameEnv/getRound"];
  }


  get wiggleCard(){
    return this.$store.getters['gameEnv/getCurrentWiggleItem'];
  }

  get cardsi() {
    return this.$store.getters['cardstore/getCards'];
  }

  get players(){
    return this.$store.getters['playerList/getPlayers'];
  }

  get playerListSize(){
   return (this.$store.getters['playerList/getPlayersCount']*100+'px');
  }

  get Points(){
    return this.$store.getters['playerList/getCurrentUser'].points;
  }

  get errors() {
    return this.$store.getters['cardstore/getCards'];
  }

  get time() {
    return this.$store.getters["gameEnv/getTimeString"];
  }

}
