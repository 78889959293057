import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0723f010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "answerInfoWrapper shadow" }
const _hoisted_2 = { class: "answerInfoText" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "answerField",
    class: _normalizeClass([!_ctx.show? 'hide':''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", { innerHTML: _ctx.currentQuestion }, null, 8, _hoisted_3)
      ]),
      _withDirectives(_createElementVNode("input", {
        id: "answerInput",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keyAction && _ctx.keyAction(...args)))
      }, null, 544), [
        [_vModelText, _ctx.answer]
      ]),
      _createElementVNode("input", {
        type: "submit",
        class: "blue-btn",
        value: "Antwort absenden",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addAnswer && _ctx.addAnswer(...args)))
      }),
      _createElementVNode("div", {
        class: "mb-1",
        innerHTML: _ctx.time
      }, null, 8, _hoisted_4)
    ])
  ], 2))
}