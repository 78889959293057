
import { Options, Vue, setup } from 'vue-class-component';
import User from "@/components/user.vue";
import {  emojisplosions } from "@/assets/js/emojisplosions"

@Options({
  props: {
  },
  components: {
    User
  },
})

export default class Results extends Vue {

  private propData:any = null;
  created() {

    let p:any = new Proxy(this.$props, {
      get: function(target, name) {
        return target[name];
      }
    });
    this.propData = p;
    const { cancel } = emojisplosions();
    setTimeout(cancel, 24000);
    var audio = new Audio(require("../assets/sounds/firework.mp3"));
    audio.play();

  }


  get ListWidth(){
    let playersCount = this.$store.getters['playerList/getPlayersCount']-1;
    return playersCount *90 ;
  }

  get amIactive(){
    let currentUserIndex =  this.$store.getters["gameEnv/getCurrentuser"];
    return  this.propData.index==currentUserIndex;
  }

  get gameMode() {
    return this.$store.getters["gameEnv/getGameMode"];
  }

  get players(){
    let player = this.$store.getters['playerList/getPlayers'];
    return player;;
  }

  get isMaster() {
    return this.$store.getters["gameEnv/isMaster"];
  }

  get ColorAndIcon(){
    let colorsAndIcons =  this.$store.getters["gameEnv/getColorsAndIcons"];
    let colors = colorsAndIcons[0];
    let icons = colorsAndIcons[1];
    let nr = this.propData.index;
    let nrcol = nr % (colors.length);
    let nricon =  nr % (icons.length);

    return [colors[nrcol],icons[nricon]];
  }

  logout() {
    this.$store.dispatch("socketStore/logout");
  }

  reset(){
    this.$store.dispatch("socketStore/reset_round");

  }
}
