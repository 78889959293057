
import {Vue} from 'vue-class-component';

export default class CreateRoom extends Vue {

  username="Spielleiter";
  timer="00:30";
  rounds="7";
  diff=3;
  password="";

  public onSubmit(e): boolean {

    e.preventDefault();
    this.$store.dispatch("socketStore/start");
    let getCardsShuffled = this.$store.getters["cardstore/getCardsShuffled"](this.diff);
    this.$store.dispatch("socketStore/createRoom",{username:this.username,password:this.password,diff:this.diff,timer:this.timer,rounds:parseInt(this.rounds),cards:getCardsShuffled});
    return false;
  }

}
