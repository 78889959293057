
import {defineComponent} from '@vue/composition-api';
import store from '@/store';

export default defineComponent({
  name: 'guide',
  data() {
    return {
      showMe: false,
      message: "asd",
      autoSaveInterval: {},
    };
  },
  computed: {
    show() {
      return this.$data.showMe;
    },
  },
  created() {
    const game = this.$store.getters['gameEnv/getGame'];
    this.autoSaveInterval = setInterval(() => {

      if(game.gameMode != 'Master'){
        return;
      }
      const idleTime: number = (Date.now() - game.timeOfLastAction) / 1000;
      // first check if we have to do something
      if (game.timeOfLastAction === 0 || idleTime >= 5) {


        // Beginning of Game, nothing happened so far
        if (game.timeOfLastAction === 0 && game.currentView === 'playing'  && game.currentRound === 0) {
         const playerCount =  this.$store.getters['playerList/getPlayersCount'];

         //Master needs to invite People
         if(playerCount<=2){
           this.$data.message = 'Lade Benutzer ein, um das Spiel zu starten.';
           this.$data.showMe = true;
           store.dispatch("gameEnv/setWiggleItem", "invitePeople");

         }else{
           //Master needs to play a card
           this.$data.message = 'Klicke auf den Kartenstapel, um das Spiel zu beginnen';
           this.$data.showMe = true;
           store.dispatch("gameEnv/setWiggleItem", "cardStack");
         }
        }else{
        //WE ARE IN THE GAME

          //We're in the game, but no timer is running
          if(game.currentView === 'playing' && !this.timerRunning){

            console.log('playing');
            console.log(game.status);
          if(game.status=="roundEnd"){
            console.log('roundEnd');
            store.dispatch("gameEnv/setWiggleItem", "cardStack");
          }else{
            console.log('roundEndelse');
            store.dispatch("gameEnv/setWiggleItem", "playerActions");
          }





          }

        }
      } else {

        this.$data.showMe = false;
        store.dispatch("gameEnv/setWiggleItem", "");
      }
    }, 5000);
  },



    get gameMode() {
      return this.$store.getters["gameEnv/getGameMode"];
    },

    get timerRunning(){
      if(this.$store.getters["gameEnv/getRound"] == 0) return false;
      if(this.$store.getters["gameEnv/getTimeString"]=="00:00"){
        return false;
      };

      return true;
    }

}
);
