import { createApp} from 'vue';
import {Vue} from "vue-class-component";
import App from './App.vue';
import store from './store';

let app = createApp(App);

app.use(store);
app.provide("$store",store);
app.config.globalProperties.$store = store;
app.mount('#app');


//create_room
/*
socketApi.send('create_room',{
  password: "123",
  username: "Maurice",
  difficulty: 1,
  timerLength: 30,
  rounds: 10,
  sound: "sound.mp3",
  socketId: null
})


*

socket.emit("login", {
room: "19AF91"
});

*
* */


