
import {defineComponent} from "@vue/composition-api";
import Clock from "@/components/clock.vue";

export default defineComponent({
  name: "gameinfo",
  components: {Clock},
  props: {
    msg: String,
  },
  setup(props, context) {

  },
  computed: {
    roomName(){
      return this.$store.getters["gameEnv/getRoomName"];
    },
    currentRound(){
      return this.$store.getters["gameEnv/getRound"];
    },
    totalRounds(){
      return this.$store.getters["gameEnv/getRounds"];
    },
    gameMode() {
      return this.$store.getters["gameEnv/getGameMode"];
    },
    points(){
      return this.$store.getters['playerList/getCurrentUser'].points;
    },
    playersCount(){
      return this.$store.getters['playerList/getPlayersCount']-1;

    }
  }
});
