
import { Options, Vue} from 'vue-class-component';
import store from "@/store";
import {SocketApi} from "@/data/socket/Socket";

@Options({
  props: {
    msg: String,
    title: String,
    subtitle: String,
    category: String,
    active: Boolean,
    cardNumber: Number,
    played: Boolean,
    image: String,
    explanation:String,
    points: Number,
    difficulty: String,
    class: String
  },
})

export default class card extends Vue {

  help = '';

  mainColor = '#8cbf45';

  subColor = '#435925';

  type = 'schwer';

  showFront = true;

  admin = false;

  showActions = true;

  private propData:any = null;
  created() {
    let p:any = new Proxy(this.$props, {
      get: function(target, name) {
        return target[name];
      }
    });
    this.propData = p;
  }

  get gameMode() {
    return this.$store.getters["gameEnv/getGameMode"];
  }

  get gameStatus(){
    return this.$store.getters['gameEnv/getStatus'];
  }

  get AnswerSize(){
    let leng = this.currentUser.answer.length;
    let size= 32;
    const maxLength = 16;
    size= (maxLength/leng)*size;
    if(size>32){
      size = 32;
    }
    return size+'px';
  }

  get currentUser(){
    return this.$store.getters["playerList/getCurrentUser"];

  }

  get timerRunning(){
    if(this.$store.getters["gameEnv/getRound"] == 0) return false;
    if(this.$store.getters["gameEnv/getTimeString"]=="00:00"){
      return false;
    };

    return true;
    }

  public showAnswer(){
    this.$store.dispatch("gameEnv/showAnswer");
  }

  public showInfo(){
    alert(this.propData.explanation);
  }

  public nextCard(): void {

    let game = this.$store.getters["gameEnv/getGame"];

    if(game.gameMode!="Master"){
      return;
    }

    let playersCount = this.$store.getters["playerList/getPlayersCount"];
    store.dispatch("gameEnv/actionMade");

    if(playersCount<3){
      store.dispatch("socketStore/errorMessage", {code:0,message:"Es werden mindestens 2 Mitspieler benötigt"});
      return;
    }

    if(game.gameMode=="Master"){
      if(game.currentRound > game.rounds){
        alert('GAME-END');
      }else if(game.currentRound ==0){
        this.$store.dispatch("gameEnv/nextRound");
        this.$store.dispatch("gameEnv/nextCard");
        SocketApi.getInstance().send("change_status","readQuestion");

      }
      else{
        this.$store.dispatch("gameEnv/nextCard");
        SocketApi.getInstance().send("change_status","readQuestion");
        //TODO: check if we have enough cards for all rounds before firing
      }

    }
    this.showFront = false;
  }


  public nextPlayer(): void {
    this.$store.dispatch("gameEnv/nextPlayer");
  }


}
