
import {Vue} from 'vue-class-component';

export default class Login extends Vue {

  username="";
  room="";
  password="";


  public onSubmit(e): boolean {

    e.preventDefault();
    this.$store.dispatch("socketStore/start")
    this.$store.dispatch("socketStore/clearMessage");

    //TODO: Validierung
    this.$store.dispatch("socketStore/login",{username:this.username,room:this.room,password:this.password});
    return false;
  }

}
