import io from "socket.io-client"
import store from "../../store/index";

export class SocketApi {

  private socket: any = null;
  private static instance: SocketApi;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new SocketApi();
    }
    return this.instance;
  }

  private constructor() {
    //this.initSocket();
  }

  public initSocket() {
    const self = this;
    if(this.socket !== null) {
      this.socket.connect();
      return;
    }
    this.socket = io(process.env.VUE_APP_SOCKET, {}); //hier socket url

    this.socket.on("connect", (data) => {
      console.log('on Connect');
      console.log(data);

      store.dispatch("socketStore/connected", this.socket.id);

      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie =
        "soid=" + this.socket.id + ";" + expires + ";path=/";
    });



    this.socket.on("gotPoints", (data) => {
      console.log('-gotPoints-');
      console.log(data);

      //store.dispatch("gameEnv/updateRoom",JSON.parse(data));
    });

    this.socket.on("answerSend", (data) => {
      console.log('-Answersend-');
      console.log(data);
     // store.dispatch("gameEnv/updateRoom",JSON.parse(data));
    });

    this.socket.on("status", (data) => {
      console.log('-status-');
      console.log(data);
      store.dispatch("gameEnv/updateRoom",JSON.parse(data));
    });

    this.socket.on("error", (data) => {
      console.log('on error');
      console.log(data.message);
      //TODO: hier nen nettes overlay für bauen
      console.log(data);
      store.dispatch("socketStore/errorMessage", data);
    });

    this.socket.on("login-error", (data) => {
      console.log('on error');
      alert(data.message);
      console.log(data);
    });

   this.socket.on("next_round", () => {
     store.dispatch("gameEnv/showAnswer");
   });

    this.socket.on("room_created", (data) => {
      store.dispatch("gameEnv/updateRoom",JSON.parse(data));
      console.log(data);
    });

    this.socket.on("job", (obj:any) => {
      switch(obj.op) {
        case 'timer':
          store.dispatch("gameEnv/updateTimer", obj.timer);
          break;
      }
    });

    this.socket.on("disconnect", () => {
      console.log(this.socket.id); // undefined
    });
    this.socket.on("connect_error", (data:any) => {
      console.log('connect error');
      console.log(data);

    });
    this.socket.on("message", function (data: any) {
      console.log(data);
    });

  }

  public disconnect() {
    if (this.socket) {
      console.log("disconnect socket");
      this.socket.sendBuffer = [];
      this.socket.disconnect()
      //this.socket = null;
    }
  }

  public close() {
    if (this.socket) {
      console.log("disconnect socket");
      this.socket.sendBuffer = [];
      this.socket.close();
      this.socket = null;
    }
  }

  public getSocketId(){
    return this.socket.id;
  }

  public send(op:string, data:any) {
    //this.initSocket();
    this.socket.emit(op, data);
  }
}
