import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43a039cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "timer" }
const _hoisted_2 = { class: "ziffernblatt d-flex flex-column justify-content-center" }
const _hoisted_3 = { class: "countTime" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["clock", [_ctx.isRunning? '':'stop']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "zeiger",
          style: _normalizeStyle({transform: _ctx.deg})
        }, null, 4)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.time), 1)
  ], 2))
}