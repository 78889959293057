/**
 * Default list of supported emojis.
 */
export const defaultEmojis = [
    "😁",
    "😂",
    "🤣",
    "😃",
    "😅",
    "😆",
    "😍",
    "🤩",
    "😎",
    "🤔",
    "😒",
    "😭",
    "😱",
    "🤖",
    "😻",
    "🙀",
    "🙈",
    "🙉",
    "🙊",
    "🏄",
    "💪",
    "👌",
    "👋",
    "🙌",
    "💝",
    "💖",
    "💗",
    "🧡",
    "💛",
    "💚",
    "💙",
    "💜",
    "🚀",
    "⛄",
    // These emoji are extra fun, so they get twice the inclusion!
    "🔥",
    "🔥",
    "✨",
    "✨",
    "🎉",
    "🎉",
    "💯",
    "💯",
];
