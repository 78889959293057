import { createStore } from 'vuex';
import cards from './cards';
import players from "./players";
import game from "@/store/game";
import socket from "@/store/socket";

export default createStore({
  state: {
  },
  getters: {
    getCookie() {
      return function (cName) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = cDecoded.split('; ');
        let res;
        cArr.forEach(val => {
          if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res
      }
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cardstore: cards,
    playerList: players,
    gameEnv: game,
    socketStore: socket

  },
});
