
import {Vue} from 'vue-class-component';
import store from "@/store";

export default class Answer extends Vue {

  answer = "";
  private propData:any = null;

  created() {
    let p:any = new Proxy(this.$props, {
      get: function(target, name) {
        return target[name];
      }
    });

    this.propData = p;
  }

   get currentQuestion() {
    let currentQuestion = this.$store.getters["cardstore/getCurrentQuestion"];
    return currentQuestion;
  }

  get show(){
    let foo=this.$store.getters["gameEnv/getAnswerVisible"];
    return foo;
  }

  public keyAction(event){
    if(event.key=="Enter"){
      var audio = new Audio(require("../assets/sounds/bell.mp3"));
      audio.play();
      this.addAnswer();
    }else{
      let soundNr = Math.floor(Math.random() * 3) + 1;
      var audio = new Audio(require("../assets/sounds/key_"+soundNr+".mp3"));
      audio.play();
    }
  }

  public addAnswer(){
    let currentUser = this.$store.getters["playerList/getCurrentUser"];
    this.$store.dispatch("gameEnv/hideAnswer");
    this.$store.dispatch("gameEnv/addAnswer",{answer:this.answer, socketId:currentUser.socketId});
  }
}
