import {SocketApi} from "@/data/socket/Socket";
import store from "@/store/index";

const
  SOCKET = "SOCKET",
  SOCKET_INIT = "SOCKET_INIT",
  SOCKET_CONNECT = "SOCKET_CONNECT",
  SOCKET_NEW_MESSAGE = "SOCKET_NEW_MESSAGE",
  SOCKET_ERROR = "SOCKET_ERROR",
  SOCKET_DISCONNECT = "SOCKET_DISCONNECT",
  SOCKET_NOMESSAGE = "SOCKET_NOMESSAGE",
  SOCKET_SET_ERROR = "SOCKET_SETERROR";

export default {
  namespaced: true,
  state: {
    connected: false,
    id: null,
    lastError: {
      code: 0,
      message: null
    }
  },
  getters: {
    isConnected(state:any) {
      return state.connected;
    },
    getId(state:any) {
      return state.id;
    },
    getErrorMessage(state) {
      return state.lastError.message;
    }
  },
  mutations: {
    [SOCKET_INIT](state:any) {
      state.connected = false;
    },
    [SOCKET_CONNECT](state:any, id:any) {
      state.connected = true;
      state.id = id;
      console.log('mutations: SOCKET_CONNECT');
    },
    [SOCKET_NEW_MESSAGE](state:any) {

    },
    [SOCKET_ERROR](state:any, error:any) {
      state.connected = false;
      console.log('mutations: SOCKET_ERROR');
      console.log(state);
    },
    [SOCKET_DISCONNECT](state:any) {
      state.connected = false;
      state.id = null;
      console.log("SOCKET_DISCONNECT");
    },
    [SOCKET_SET_ERROR](state, error) {
      state.lastError.code = 0;
      state.lastError.message = error.message;
    }
  },
  actions: {
    clearMessage({commit}:any) {
      commit(SOCKET_SET_ERROR, {code:null, message:null});
    },
    errorMessage({commit}:any, error:{code?:number, message:string}) {
      commit(SOCKET_SET_ERROR, error);
    },
    checkReConnect({commit}:any) {
      let socketId = store.getters["getCookie"]("soid");
      if(socketId!="") {
        console.log("old id found!");
        let socket = SocketApi.getInstance();
        socket.initSocket();
        socket.send('re_login',{
          id: socketId
        })
      }
    },
    async start({commit}:any, user:any) {
      commit(SOCKET_INIT);
      try {
        let socket = SocketApi.getInstance();
        socket.initSocket();
        return null;
      } catch (error) {
        commit(SOCKET_ERROR, error);
        return null;
      }
    },
    async disconnect({commit}:any) {
      commit(SOCKET_INIT);
      try {
        let socket = SocketApi.getInstance();
        socket.disconnect();
        commit(SOCKET_DISCONNECT);
        return null;
      } catch (error) {
        commit(SOCKET_ERROR, error);
        return null;
      }
    },
    connected({commit}:any, id:string) {
      commit(SOCKET_CONNECT, id);
    },
    disconnected({commit}:any, id:string) {
      commit(SOCKET_DISCONNECT);
    },
    async createRoom({commit}:any, data:any) {
      let socket = SocketApi.getInstance();
      socket.send('create_room',{
        password: data.password,
        username: data.username,
        difficulty: data.diff,
        timerLength: data.timer,
        rounds: data.rounds,
        sound: "clock.mp3",
        socketId: null,
        cards: data.cards,
      })
    },

    reset_round({commit, state}:any) {
      let socket = SocketApi.getInstance();
      store.dispatch("gameEnv/resetGame");
      store.dispatch("cardstore/clear");
      socket.send("reset_round",{round:0});
    },

    async login({commit}:any, data:any) {
      let socket = SocketApi.getInstance();
      console.log(data);
      socket.send('login',{
        room: data.room,
        username: data.username,
        password: data.password
      })
    },

    logout({commit, state}:any) {
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie =
        "soid=;" + expires + ";path=/";


      let socket = SocketApi.getInstance();
      socket.send("job", {op:"open", cards:1});

      socket.send('logout', {});
      socket.disconnect();
      store.dispatch("gameEnv/clear");
      store.dispatch("cardstore/clear");
    }
  }
}
