
import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: "clock",
  props: {
    msg: String,
  },
  setup(props, context) {

  },
  computed: {
    time() {
      return this.$store.getters["gameEnv/getTimeString"];
    },
    deg() {
      let time = this.$store.getters["gameEnv/getCurrentTime"];
      let deg = 360 - ((60 - time.sec) * 6);
      return 'rotate('+deg+'deg)';
    },
    isRunning() {
      if(this.$store.getters["gameEnv/getRound"] == 0) return true;
      return this.$store.getters["gameEnv/isTimeRunning"];
    }
  }
});
